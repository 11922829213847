import styled from "styled-components";

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: url('./assets/background.jpg') no-repeat center center;
  background-size: cover;
//  background: rgba(0,0,0,0);
  .hero-text{
    & > p{
      font-size: 1.8rem;
    }
    h1{
      font-size: 7rem;
    }

    h3{
      color: #A91B60;
      margin: 1rem 0;
    }

    
    p.small-resume {
      margin-bottom: 5rem;
    }
  }

  .social-media{
    display: inline-flex;
    width: 2rem;
   // align-items: center;
    justify-content: space-between;
    gap:  3rem;
    padding-top: 0;
    padding-left:25rem;
    margin-top: -35px;

    img {
      width: 4rem; /* Adjust this value as needed */
      height: 4rem; /* Adjust this value as needed */
    
    }
  
    span {
      font-size: 4rem; /* Adjust this value as needed */
    }
  }



  


  


  .button{
    margin-top: 5rem;
    padding: 1.4rem 6rem;
    
  }


  .my-image {
    text-align: left; /* Aligns the image to the left */
    margin-top: -20px; /* Moves the image up; adjust as needed */
    margin-left: -10px; /* Adjust to move the image left or right */
    width: 500px;
  }
  
  .my-image img {
    width: 280px; /* Adjust the width as needed */
    height: auto; /* Maintains aspect ratio */
    margin-left: 0; /* Ensure no additional left margin */
  }
  



  @media(max-width: 960px){
    display: block;
    margin-top: 15%;
    .hero-text{

      h1{
        font-size: 5rem;
      }
    }
    
  }

  @media(max-width: 600px){
    margin-top: 35%;
  }
  @media(max-width: 480px){
    margin-top: 45%;
  }
`
