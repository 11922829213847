import { BrowserRouter} from "react-router-dom"
import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"
import MyPhoto from "../../assets/better.png" // Import your personal image
import { NavHashLink } from "react-router-hash-link"
import linkedin from '../../assets/linkedin.svg'
import githubIcon from '../../assets/github.svg'
import Hello from '../../assets/Hello.gif'


export function Hero() {
  return (
    <Container id="home">
            <style>
        {`
          .button:hover {
            background-color: 14452fF; /* Change this to the desired hover color */
            color: white; /* Change this to the desired text color on hover */
          }
        `}
      </style>
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
        <p style={{ fontSize: '2rem' }}>
            Hello <img src={Hello} alt="Hello" style={{ width: '30px', height: '30px' }} />, I'm
          </p>
          {/* <p>Hello <img src={Hello} alt="Hello" width="20px"/>, I'm</p> */}
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Naina Gupta</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Software Developer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">Software Engineering Student at University of Calgary</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to="#contact" className="button">Contact Me</NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
          <div className="social-media">
            <a
              href="https://www.linkedin.com/in/nainaguptaa/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="Linkedin" />
            </a>
            <a
              href="https://github.com/nainaguptaa"
              target="_blank"
              rel="noreferrer"
            >
              <img src={githubIcon} alt="GitHub" />
            </a>
          </div>
        </ScrollAnimation>
      </div>
      <div className="my-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img src={MyPhoto} alt="Naina Gupta" /> 
        </ScrollAnimation>
      </div>
    </Container>
  )
}

