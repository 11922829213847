import ScrollAnimation from 'react-animate-on-scroll';
import './Timeline.css'; 

const Timeline = () => {
  return (
    <div className="timeline">
      <div className="timeline-line">
        <div className="arrow-left"></div> 
      </div>
      <ScrollAnimation animateIn="fadeInUp">
        <div className="timeline-item">
          <div className="timeline-header">
            <h4>Bell Canada</h4>
            <span>May 2024 - Aug 2024</span>
          </div>
          <div className="timeline-content">
            <p>Software Developer Intern</p>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" delay={0.1 * 1000}>
        <div className="timeline-item">
          <div className="timeline-header">
            <h4>City of Calgary</h4>
            <span>May 2023 - Aug 2023</span>
          </div>
          <div className="timeline-content">
            <p>IT Cloud Development & Vendor Management Intern</p>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
        <div className="timeline-item">
          <div className="timeline-header">
            <h4>Solar Car Club @ UCalgary</h4>
            <span>Sept 2022 - Jan 2024</span>
          </div>
          <div className="timeline-content">
            <p>Software Team Member</p>
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" delay={0.3 * 1000}>
        <div className="timeline-item">
          <div className="timeline-header">
            <h4>University of Calgary</h4>
            <span>Sept 2021 - May 2026</span>
          </div>
          <div className="timeline-content">
            <p>Software Engineering Student</p>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Timeline;

