// import { Container, ContainerSucces } from './styles'
// import { useForm, ValidationError } from '@formspree/react'
// import { toast, ToastContainer } from 'react-toastify'
// import ReCAPTCHA from 'react-google-recaptcha'
// import { useEffect, useState } from 'react'
// import validator from 'validator'

// export function Form() {
//   const [state, handleSubmit] = useForm('xknkpqry')
//   const [validEmail, setValidEmail] = useState(false)
//   const [isHuman, setIsHuman] = useState(false)
//   const [message, setMessage] = useState('')
//   function verifyEmail(email: string) {
//     if (validator.isEmail(email)) {
//       setValidEmail(true)
//     } else {
//       setValidEmail(false)
//     }
//   }
//   useEffect(() => {
//     if (state.succeeded) {
//       toast.success('Email successfully sent!', {
//         position: toast.POSITION.BOTTOM_LEFT,
//         pauseOnFocusLoss: false,
//         closeOnClick: true,
//         hideProgressBar: false,
//         toastId: 'succeeded',
//       })
//     }
//   })
//   if (state.succeeded) {
//     return (
//       <ContainerSucces>
//         <h3>Thanks for getting in touch!</h3>
//         <button
//           onClick={() => {
//             window.scrollTo({ top: 0, behavior: 'smooth' })
//           }}
//         >
//           Back to the top
//         </button>
//         <ToastContainer />
//       </ContainerSucces>
//     )
//   }
//   return (
//     <Container>
//       <h2>Get in touch using the form</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           placeholder="Email"
//           id="email"
//           type="email"
//           name="email"
//           onChange={(e) => {
//             verifyEmail(e.target.value)
//           }}
//           required
//         />
//         <ValidationError prefix="Email" field="email" errors={state.errors} />
//         <textarea
//           required
//           placeholder="Send a message to get started."
//           id="message"
//           name="message"
//           onChange={(e) => {
//             setMessage(e.target.value)
//           }}
//         />
//         <ValidationError
//           prefix="Message"
//           field="message"
//           errors={state.errors}
//         />
//         <ReCAPTCHA
//           sitekey="6Lfj9NYfAAAAAP8wPLtzrsSZeACIcGgwuEIRvbSg"
//           onChange={(e) => {
//             setIsHuman(true)
//           }}
//         ></ReCAPTCHA>
//         <button
//           type="submit"
//           disabled={state.submitting || !validEmail || !message || !isHuman}
//         >
//           Submit
//         </button>
//       </form>
//       <ToastContainer />
//     </Container>
//   )
// }


import { Container, ContainerSucces } from './styles'
import { useForm, ValidationError } from '@formspree/react'
import { toast, ToastContainer } from 'react-toastify'
import { useEffect, useState } from 'react'
import validator from 'validator'

export function Form() {
  const [state, handleSubmit] = useForm('xpwaqkrg')
  const [validEmail, setValidEmail] = useState(false)
  const [message, setMessage] = useState('')
  
  function verifyEmail(email: string) {
    if (validator.isEmail(email)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  useEffect(() => {
    if (state.succeeded) {
      toast.success('Email successfully sent!', {
        position: toast.POSITION.BOTTOM_LEFT,
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: 'succeeded',
      })
    }
  }, [state.succeeded])

  if (state.succeeded) {
    return (
      <ContainerSucces>
        <h3 style={{ fontSize: '3rem' }}>Thanks for getting in touch!</h3>

        <button
  onClick={() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }}
  style={{
    fontSize: '2rem', // Increases the font size
    padding: '1.5rem 3rem', // Increases the padding for a bigger button
    borderRadius: '1rem', // Optional: Makes the corners rounded
  }}
>
  Back to the top
</button>
        <ToastContainer />
      </ContainerSucces>
    )
  }

  return (
    <Container>
      <h2>OR... Get in touch with this form</h2>
      <form onSubmit={handleSubmit}>
        <input
          placeholder="Your Email"
          id="email"
          type="email"
          name="email"
          onChange={(e) => {
            verifyEmail(e.target.value)
          }}
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <textarea
          required
          placeholder="Your Message"
          id="message"
          name="message"
          onChange={(e) => {
            setMessage(e.target.value)
          }}
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <button
          type="submit"
          disabled={state.submitting || !validEmail || !message}
        >
          Submit
        </button>
      </form>
      <ToastContainer />
    </Container>
  )
}

